.spin {
  animation: rotation 2s infinite linear;
}

.rotate90:not(.spin) {
  transform: rotate(90deg);
}
.rotateMinus90:not(.spin) {
  transform: rotate(-90deg);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
